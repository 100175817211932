<template>
  <div class="register">
    <div class="content">
      <p class="mb-6 register__text" v-if="$route.name === 'TgRegister' && realStep === 1">
        Для получения доступа к Telegram-каналу
        <router-link
          :to="{
            name: 'Login',
            params: { tg: true },
            query: this.$route.query,
          }"
        >авторизуйтесь</router-link
        > или зарегистрируйтесь в экосистеме AZ-MOST
      </p>
      <div class="register__row">
        <RegisterSteps
          v-if="!hideLeft"
          :step="step"
          :title="'Регистрация'"
          :stepList="stepList"
        />
        <RegisterForm @changeStep="changeStep" @realStep="changeRealStep"/>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from "../../components/auth/RegisterForm.vue";
import RegisterSteps from "../../components/auth/RegisterSteps.vue";

export default {
  metaInfo: {
    title: "Регистрация",
  },
  name: "Register",
  data: () => ({
    step: 1,
    hideLeft: false,
    realStep: 1,
    stepList: [
      "Шаг 1. Email",
      "Шаг 2. Личные данные",
      "Шаг 3. Место работы",
      "Шаг 4. Номер телефона и пароль",
    ],
  }),
  methods: {
    changeRealStep(realStep) {
      this.realStep = realStep
    },
    changeStep(stepNumber, hideLeft ) {
      if (hideLeft) {
        this.hideLeft = true;
      }
      this.step = stepNumber;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "registration steps", {
          "registration steps": {
            step: stepNumber.toString(),
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  mounted() {
    if (typeof ym !== "undefined") {
      ym(91468266, "reachGoal", "registration steps", {
        "registration steps": {
          step: "1",
          ...this.$root.ymFields,
        },
      });
    }
  },
  components: { RegisterSteps, RegisterForm },
};
</script>

<style lang="scss" scoped>
.register {
  height: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
    height: auto;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    & a {
      color: #830051;
      text-decoration: underline;
    }
  }
}
</style>